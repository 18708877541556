import React, { useState, useEffect } from "react" 

const RecipesPage = () => {
  
  const [recipes, setRecipes] = useState(0);
  useEffect(() => {
    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------
    fetch(`/wp-json/wp/v2/posts?categories=58&order=desc&orderby=date&per_page=6`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
          //console.log("the returned result is ");
          //console.log(resultData);
          setRecipes(resultData);          
      });
  }, []);

  return (
    <section>
      Number of recipes : { Array.isArray(recipes) && recipes.length} <br/>
      
      { Array.isArray(recipes) && recipes.map(r => {
          return (
            <div key={r.id}>              
              <h3><span dangerouslySetInnerHTML={{__html: r.title.rendered}} /></h3>              
              <span dangerouslySetInnerHTML={{__html: r.excerpt.rendered}} />
              <a href={r.guid.rendered}>Read more »</a><br/>
              Posted by {r.author} in {r.categories.toString()}
            </div>)
        })      
      }
      
    </section>
  )
}

export default RecipesPage